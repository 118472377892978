.App {
  text-align: center;
}
.circle {
  width: 30px;
  height: 30px;
  font-size: 13px;
  text-align: center;
  color: #fff;
}
.recharts-cartesian-axis-tick-value {
  font-size: 10px;
  color: black;
}
.dashed-table td, th {
  border-bottom: dashed #888 1px;
}

.speedLessThan5 {
  background-color: #e1dada;
}
.speed5to8 {
  background-color: #8AF8F4;
}
.speed9to13 {
  background-color: #82F8C1;
}
.speed14to19 {
  background-color: #78F700;
}

.table.scroll-table {
  width: 1000px;
  overflow: auto;
}
.speed20to29 {
  background-color: #FF3C12
}
.speed30to35 {
  background-color: #A60027
}
.speed36plus {
  background-color: #E000AC
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.table.table-float {
  border-top: 1px solid grey;
}

td, th {
  margin: 0;
  border: 1px solid grey;
  white-space: nowrap;
  border-top-width: 0px;
}

.spot-name {
  width: 100px;
}
.spot-wind {
}

th.spot-time-graph {
  width: 500px;
}

th span {
  font-weight: 300;
}


.float-table-wrapper {
  width: 100%;
  overflow-x: scroll;
  margin-left: 125px;
  overflow-y: visible;
  padding: 0;
}

.timecol {
  white-space: pre-wrap;
  text-align: center;
}

.headcol {
  z-index: 1; 
  position: absolute;
  max-width: 125px;
  white-space: pre-wrap;
  overflow: hidden;
  overflow-wrap: break-word;
  left: 0;
  top: auto;
  border-top-width: 1px;
  /*only relevant for first row*/
  margin-top: -1px;
  /*compensate for top border*/
}
@font-face {
  font-family: "Luckiest Guy Regular";
  src: local("LuckiestGuyRegular"),
    url("./LuckiestGuy-Regular.ttf") format("truetype");
  font-weight: normal;
}

.brand-text {
  font-family: "Luckiest Guy Regular"
}
.history-chart: {
  overflow-x: scroll;
}
.long {
  background: yellow;
  letter-spacing: 1em;
}

.tscroll {
  width: 100%;
  overflow-x: scroll;
  z-index: 0;
}
.table.tscroll table tr.spot {
  border-bottom: solid rgb(62, 85, 105) 1px;
  padding: 1px;
}
.tscroll table td.tab:first-child {
  position: sticky;
  left: 0;
  background: rgba(255,255,255,0.85);
}

.tscroll td, .tscroll th {
  border-bottom: dashed #888 1px;
  font-size: 12px;
  padding: 1px;
}
.table td.speedbox, .table th {
  padding: 1px;
}
